"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useStopBodyScroll = function (_a) {
    var isShowMobileNav = _a.isShowMobileNav, isShowPopUp = _a.isShowPopUp;
    var _b = react_1.useState(false), isStopBodyScroll = _b[0], setIsStopBodyScroll = _b[1];
    react_1.useEffect(function () {
        if (isShowMobileNav || isShowPopUp) {
            setIsStopBodyScroll(true);
        }
        else {
            setIsStopBodyScroll(false);
        }
    }, [isShowMobileNav, isShowPopUp]);
    return { isStopBodyScroll: isStopBodyScroll };
};
exports.default = useStopBodyScroll;
