"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _a = process.env, URL_DOMAIN = _a.URL_DOMAIN, HEADER_IMAGES_PATH = _a.HEADER_IMAGES_PATH, SLIDER_IMAGES_PATH = _a.SLIDER_IMAGES_PATH, CLIENT_BRANDS_IMAGES_PATH = _a.CLIENT_BRANDS_IMAGES_PATH, NINEYI_FEATURE_IMAGES_PATH = _a.NINEYI_FEATURE_IMAGES_PATH, THREE_HUNDRED_SIXTY_IMAGES_PATH = _a.THREE_HUNDRED_SIXTY_IMAGES_PATH, LATEST_CASES_IMAGES_PATH = _a.LATEST_CASES_IMAGES_PATH, NEW_BRANDS_IMAGES_PATH = _a.NEW_BRANDS_IMAGES_PATH, PARTNER_BRANDS_IMAGES_PATH = _a.PARTNER_BRANDS_IMAGES_PATH, BOTTOM_IMAGES_PATH = _a.BOTTOM_IMAGES_PATH, FOOTER_IMAGES_PATH = _a.FOOTER_IMAGES_PATH, CONTENTFUL_HOME_IMAGES_PATH = _a.CONTENTFUL_HOME_IMAGES_PATH, CONTENTFUL_SHOWCASE_BOTTOM_IMAGES_PATH = _a.CONTENTFUL_SHOWCASE_BOTTOM_IMAGES_PATH, CONTENTFUL_SHOWCASE_CASES_IMAGES_PATH = _a.CONTENTFUL_SHOWCASE_CASES_IMAGES_PATH, CONTENTFUL_SHOWCASE_SLIDE_IMAGES_PATH = _a.CONTENTFUL_SHOWCASE_SLIDE_IMAGES_PATH, CONTENTFUL_PARTNER_IMAGES_PATH = _a.CONTENTFUL_PARTNER_IMAGES_PATH;
exports.headerImgLinks = {
    logoLink: URL_DOMAIN + "/",
    logoImgLink: "" + URL_DOMAIN + HEADER_IMAGES_PATH + "/logo.png",
    navDropDownArrowImg: "" + URL_DOMAIN + HEADER_IMAGES_PATH + "/nav-down-arrow.png",
};
exports.silderImgLinks = {
    firstSlideContentBgLink: "" + URL_DOMAIN + SLIDER_IMAGES_PATH + "/first-slider-content-bg.png",
    firstSlidePopUpBgLink: "" + URL_DOMAIN + SLIDER_IMAGES_PATH + "/first-slider-popup-bg.png",
    firstSlideDeviceImgLink: "" + URL_DOMAIN + SLIDER_IMAGES_PATH + "/first-slider-device-img.png",
    desktopImgLink01: "" + URL_DOMAIN + SLIDER_IMAGES_PATH + "/slide01.jpg",
    mobileImgLink01: "" + URL_DOMAIN + SLIDER_IMAGES_PATH + "/mobile-slide01.jpg",
    desktopImgLink02: "" + URL_DOMAIN + SLIDER_IMAGES_PATH + "/slide02.jpg",
    mobileImgLink02: "" + URL_DOMAIN + SLIDER_IMAGES_PATH + "/mobile-slide02.png",
    desktopImgLink03: "" + URL_DOMAIN + SLIDER_IMAGES_PATH + "/slide03.jpg",
    mobileImgLink03: "" + URL_DOMAIN + SLIDER_IMAGES_PATH + "/mobile-slide03.jpg",
};
exports.clientBrandsImgLinks = {
    oneThousandImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/10000.png",
    logoImgLinkList: {
        jpmedImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_01.png",
        snowpeakImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_04.png",
        pumaImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_03.png",
        makeupforeverImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_12.png",
        columbiaImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_08.png",
        timberlandImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_09.png",
        vansImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_18.png",
        soniceImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_19.png",
        northfaceImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_15.png",
        triumphImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_05.png",
        philipsImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_23.png",
        dickiesImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_13.png",
        skechersImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_14.png",
        cosmedImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_07.png",
        wacoalImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_10.png",
        familyImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_11.jpg",
        stayrealImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_25.png",
        nikoImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_16.png",
        durexImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_17.png",
        levisImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_20.png",
        kiplingImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_21.png",
        poyabuyImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_22.png",
        threemImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_24.png",
        lanewImgLink: "" + URL_DOMAIN + CLIENT_BRANDS_IMAGES_PATH + "/logo_02.png",
    },
};
exports.nineyiFeatureImgLinks = {
    featureImglink01: "" + URL_DOMAIN + NINEYI_FEATURE_IMAGES_PATH + "/pic01.png",
    featureImglink02: "" + URL_DOMAIN + NINEYI_FEATURE_IMAGES_PATH + "/pic02.png",
    featureImglink03: "" + URL_DOMAIN + NINEYI_FEATURE_IMAGES_PATH + "/pic03.png",
};
exports.threeHundredSixtyBrandImgLinks = {
    sectionTitleImgLink: "" + URL_DOMAIN + THREE_HUNDRED_SIXTY_IMAGES_PATH + "/360.png",
    iconImglink01: "" + URL_DOMAIN + THREE_HUNDRED_SIXTY_IMAGES_PATH + "/icon01.png",
    iconImglink02: "" + URL_DOMAIN + THREE_HUNDRED_SIXTY_IMAGES_PATH + "/icon02.png",
    iconImglink03: "" + URL_DOMAIN + THREE_HUNDRED_SIXTY_IMAGES_PATH + "/icon03.png",
    iconImglink04: "" + URL_DOMAIN + THREE_HUNDRED_SIXTY_IMAGES_PATH + "/icon04.png",
    iconImglink05: "" + URL_DOMAIN + THREE_HUNDRED_SIXTY_IMAGES_PATH + "/icon05.png",
    iconImglink06: "" + URL_DOMAIN + THREE_HUNDRED_SIXTY_IMAGES_PATH + "/icon06.png",
};
exports.lastestCaseImgLinks = {
    photoImglink01: "" + URL_DOMAIN + LATEST_CASES_IMAGES_PATH + "/case_pic01.png",
    logoImglink01: "" + URL_DOMAIN + LATEST_CASES_IMAGES_PATH + "/case_logo01.png",
    photoImglink02: "" + URL_DOMAIN + LATEST_CASES_IMAGES_PATH + "/case_pic02.png",
    logoImglink02: "" + URL_DOMAIN + LATEST_CASES_IMAGES_PATH + "/case_logo02.png",
    photoImglink03: "" + URL_DOMAIN + LATEST_CASES_IMAGES_PATH + "/case_pic03.png",
    logoImglink03: "" + URL_DOMAIN + LATEST_CASES_IMAGES_PATH + "/case_logo03.png",
};
exports.newBrandImgLinks = {
    imglink01: "" + URL_DOMAIN + NEW_BRANDS_IMAGES_PATH + "/newcase01.png",
    imglink02: "" + URL_DOMAIN + NEW_BRANDS_IMAGES_PATH + "/newcase02.png",
    imglink03: "" + URL_DOMAIN + NEW_BRANDS_IMAGES_PATH + "/newcase03.png",
    imglink04: "" + URL_DOMAIN + NEW_BRANDS_IMAGES_PATH + "/newcase04.png",
};
exports.partnerBrandImgLinks = {
    googleImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner01.png",
    lineBuyImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner02.png",
    linePayImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner03.png",
    sevenElevenImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner04.png",
    familyImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner05.png",
    jkosImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner06.png",
    criteoImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner07.png",
    omnichatImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner08.png",
    choiceImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner09.png",
    gooDealImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner10.png",
    metaImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner11.png",
    cresclabImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner12.png",
    boxfulImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner13.png",
    hctImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner14.png",
    awooImgLink: "" + URL_DOMAIN + PARTNER_BRANDS_IMAGES_PATH + "/partner15.png",
};
exports.bottomImgLinks = {
    mapBgImgLink: "" + URL_DOMAIN + BOTTOM_IMAGES_PATH + "/mapbg.jpg",
    bottomOrangebgImgLink: "" + URL_DOMAIN + BOTTOM_IMAGES_PATH + "/bottombg.jpg",
    bottomPhoneImgLink: "" + URL_DOMAIN + BOTTOM_IMAGES_PATH + "/phone.png",
};
exports.footerImgLinks = {
    fbImglink: "" + URL_DOMAIN + FOOTER_IMAGES_PATH + "/FB_logo.png",
    igImgLink: "" + URL_DOMAIN + FOOTER_IMAGES_PATH + "/IG_logo.png",
    ytImgLink: "" + URL_DOMAIN + FOOTER_IMAGES_PATH + "/YT_logo.png",
    linkedinImgLink: "" + URL_DOMAIN + FOOTER_IMAGES_PATH + "/Linkedin_logo.png",
    lineImgLink: "" + URL_DOMAIN + FOOTER_IMAGES_PATH + "/LINE_logo.png",
};
exports.contentfulImagePath = {
    homepageImgPath: "" + URL_DOMAIN + CONTENTFUL_HOME_IMAGES_PATH + "/",
    showcaseBottomImgPath: "" + URL_DOMAIN + CONTENTFUL_SHOWCASE_BOTTOM_IMAGES_PATH + "/",
    showcaseCaseImgPath: "" + URL_DOMAIN + CONTENTFUL_SHOWCASE_CASES_IMAGES_PATH + "/",
    showcaseSlideImgPath: "" + URL_DOMAIN + CONTENTFUL_SHOWCASE_SLIDE_IMAGES_PATH + "/",
    partnerPageSuccessPartnerImgPath: "" + URL_DOMAIN + CONTENTFUL_PARTNER_IMAGES_PATH + "/",
};
