"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var homeImgLinkConfig_1 = require("@configs/homeImgLinkConfig");
var sectionTitleImgLink = homeImgLinkConfig_1.threeHundredSixtyBrandImgLinks.sectionTitleImgLink, iconImglink01 = homeImgLinkConfig_1.threeHundredSixtyBrandImgLinks.iconImglink01, iconImglink02 = homeImgLinkConfig_1.threeHundredSixtyBrandImgLinks.iconImglink02, iconImglink03 = homeImgLinkConfig_1.threeHundredSixtyBrandImgLinks.iconImglink03, iconImglink04 = homeImgLinkConfig_1.threeHundredSixtyBrandImgLinks.iconImglink04, iconImglink05 = homeImgLinkConfig_1.threeHundredSixtyBrandImgLinks.iconImglink05, iconImglink06 = homeImgLinkConfig_1.threeHundredSixtyBrandImgLinks.iconImglink06;
exports.threeHundredSixtyBrandCongfig = {
    sectionTitle: 'D2C 品牌六大服務',
    sectionSubtitle: '',
    sectionBgColor: '#f5f9fc',
    sectionTitleImg: sectionTitleImgLink,
    introList: [
        {
            img: iconImglink01,
            description: '商務成長顧問',
        },
        {
            img: iconImglink02,
            description: '系統整合服務',
        },
        {
            img: iconImglink03,
            description: '品牌代營運服務',
        },
        {
            img: iconImglink04,
            description: '數據資料服務',
        },
        {
            img: iconImglink05,
            description: 'CRM 服務',
        },
        {
            img: iconImglink06,
            description: '全媒體規劃與執行',
        },
    ],
    goMoreInfoId: 'GA-threeHundredSixtyBrand-goMoreBtn',
    goMoreInfoText: '看 360 品牌數位代營運介紹',
    goMoreInfoTextLink: '/360-tp-operation/',
};
