"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var homeImgLinkConfig_1 = require("@configs/homeImgLinkConfig");
var googleImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.googleImgLink, lineBuyImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.lineBuyImgLink, linePayImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.linePayImgLink, sevenElevenImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.sevenElevenImgLink, familyImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.familyImgLink, jkosImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.jkosImgLink, criteoImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.criteoImgLink, omnichatImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.omnichatImgLink, choiceImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.choiceImgLink, gooDealImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.gooDealImgLink, metaImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.metaImgLink, cresclabImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.cresclabImgLink, boxfulImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.boxfulImgLink, hctImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.hctImgLink, awooImgLink = homeImgLinkConfig_1.partnerBrandImgLinks.awooImgLink;
exports.partnerBrandSectionConfig = {
    sectionBgColor: '#f5f9fc',
    sectionTitle: '佈局完整的虛實融合生態圈',
    logoList: [
        {
            imgUrl: metaImgLink,
            alt: 'Meta Logo',
        },
        {
            imgUrl: googleImgLink,
            alt: 'Google Partner Logo',
            style: { objectFit: 'cover' },
        },
        {
            imgUrl: lineBuyImgLink,
            alt: 'LINE 購物 Logo',
        },
        {
            imgUrl: linePayImgLink,
            alt: 'LINE Pay Logo',
        },
        {
            imgUrl: sevenElevenImgLink,
            alt: '7ELEVEN Logo',
        },
        {
            imgUrl: familyImgLink,
            alt: 'Family Mart Logo',
        },
        {
            imgUrl: jkosImgLink,
            alt: '街口支付 Logo',
        },
        {
            imgUrl: criteoImgLink,
            alt: 'Criteo Logo',
        },
        {
            imgUrl: omnichatImgLink,
            alt: 'Omnichat Logo',
        },
        {
            imgUrl: choiceImgLink,
            alt: 'Choice Logo',
        },
        {
            imgUrl: gooDealImgLink,
            alt: 'GooDeal Logo',
        },
        {
            imgUrl: cresclabImgLink,
            alt: '漸強實驗室 Logo',
        },
        {
            imgUrl: awooImgLink,
            alt: 'awoo Logo',
        },
        {
            imgUrl: boxfulImgLink,
            alt: 'Boxful Logo',
        },
        {
            imgUrl: hctImgLink,
            alt: '新竹物流 Logo',
        },
    ],
    goPartberProgramBtnText: '申請加入 91APP 策略合作夥伴',
    goPartberProgramBtnLink: '/strategic-partner-program/',
};
