"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useSliderState = function (silderLength, initialSlideState) {
    var _a = react_1.useState(initialSlideState), slideState = _a[0], setSlideState = _a[1];
    var activeSlideIndex = slideState.activeSlideIndex, autoSlidingDuration = slideState.autoSlidingDuration;
    react_1.useEffect(function () {
        var timer = setInterval(function () {
            if (activeSlideIndex < silderLength - 1) {
                setSlideState(__assign(__assign({}, slideState), { activeSlideIndex: activeSlideIndex + 1 }));
            }
            else {
                setSlideState(__assign(__assign({}, slideState), { activeSlideIndex: 0 }));
            }
        }, autoSlidingDuration * 1000);
        return function () { return clearInterval(timer); };
    });
    var nextSlide = function (e) {
        e.stopPropagation();
        setSlideState(__assign(__assign({}, slideState), { activeSlideIndex: activeSlideIndex === silderLength - 1 ? 0 : activeSlideIndex + 1 }));
    };
    var prevSlide = function (e) {
        e.stopPropagation();
        setSlideState(__assign(__assign({}, slideState), { activeSlideIndex: activeSlideIndex === 0 ? silderLength - 1 : activeSlideIndex - 1 }));
    };
    var changeSlideIndex = function (e) {
        e.persist();
        var clickIndex = e.target.dataset.index;
        setSlideState(__assign(__assign({}, slideState), { activeSlideIndex: Number(clickIndex) }));
    };
    return { activeSlideIndex: activeSlideIndex, nextSlide: nextSlide, prevSlide: prevSlide, changeSlideIndex: changeSlideIndex };
};
exports.default = useSliderState;
