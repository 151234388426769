"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var homeImgLinkConfig_1 = require("@configs/homeImgLinkConfig");
exports.logoLink = homeImgLinkConfig_1.headerImgLinks.logoLink, exports.logoImgLink = homeImgLinkConfig_1.headerImgLinks.logoImgLink, exports.navDropDownArrowImg = homeImgLinkConfig_1.headerImgLinks.navDropDownArrowImg;
exports.navInfoListConfig = [
    {
        name: '產品價值',
        slug: '/product-overview/',
        hasSubItems: true,
        subItems: [
            {
                name: 'D2C動能',
                slug: '/d2c/',
            },
            {
                name: '核心產品',
                slug: '/product-overview/',
            },
        ],
    },
    {
        name: '方案介紹',
        slug: '/solutions/',
        hasSubItems: true,
        subItems: [
            {
                name: '方案與服務總覽',
                slug: '/solutions/',
            },
            {
                name: 'APP旗艦方案',
                slug: '/appecommerce/',
            },
            {
                name: '門市企業方案',
                slug: '/omnichannel/',
            },
            {
                name: '門市尊爵方案',
                slug: '/omni-channel-plus/',
            },
            {
                name: 'OMO旗艦方案',
                slug: '/omocrm/',
            },
            {
                name: '跨境電商專區',
                slug: '/cross-border/',
            },
            {
                name: '360品牌數位代營運',
                slug: '/360-tp-operation/',
            },
        ],
    },
    {
        name: '成功案例',
        slug: '/leading-brand/',
        hasSubItems: true,
        subItems: [
            {
                name: '最新案例',
                slug: '/showcases/',
            },
            {
                name: '示範品牌',
                slug: '/leading-brand/',
            },
        ],
    },
    {
        name: '合作夥伴',
        slug: '/partner-program/',
        hasSubItems: true,
        subItems: [
            {
                name: '合作夥伴計畫',
                slug: '/partner-program/',
            },
            {
                name: '尋找合作夥伴',
                slug: '/partner/',
            },
        ],
    },
    {
        name: '品牌商務趨勢',
        slug: '/blog/',
        hasSubItems: true,
        subItems: [
            {
                name: '2024 台灣 OMO 趨勢洞察報告',
                slug: '/2024-trend-report/',
            },
            {
                name: '2023 品牌商務趨勢報告',
                slug: '/trend/',
            },
        ],
    },
    {
        name: '新聞中心',
        slug: '/news/',
        hasSubItems: true,
        subItems: [
            {
                name: '91APP焦點',
                slug: '/media-report/',
            },
        ],
    },
    {
        name: '投資人關係',
        slug: 'https://ir.91app.com/tw/index.php',
        hasSubItems: false,
        subItems: [],
    },
    {
        name: '品牌新零售學院',
        slug: '/blog/',
        hasSubItems: false,
        subItems: [],
    },
];
