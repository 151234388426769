"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var homeImgLinkConfig_1 = require("@configs/homeImgLinkConfig");
var mapBgImgLink = homeImgLinkConfig_1.bottomImgLinks.mapBgImgLink;
exports.growthNumberConfig = {
    sectionTitle: '91APP 服務品牌持續成長',
    sectionBgImg: mapBgImgLink,
    dataList: [
        {
            number: 10000,
            unit: '家',
            description: '合作品牌數',
        },
        {
            number: 100,
            unit: '億',
            description: '年交易銷售額',
        },
        {
            number: 50,
            unit: '%',
            description: 'OMO 品牌業績成長',
        },
        {
            number: 200,
            unit: '%',
            description: '消費頻次及貢獻度',
        },
    ],
};
