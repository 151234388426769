"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useMobileNav = function () {
    var _a = react_1.useState(false), isShowMobileNav = _a[0], setIsShowMobileNav = _a[1];
    var toggleMobileNav = function () {
        setIsShowMobileNav(!isShowMobileNav);
    };
    return { isShowMobileNav: isShowMobileNav, toggleMobileNav: toggleMobileNav };
};
exports.default = useMobileNav;
