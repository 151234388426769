"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var homeImgLinkConfig_1 = require("@configs/homeImgLinkConfig");
var oneThousandImgLink = homeImgLinkConfig_1.clientBrandsImgLinks.oneThousandImgLink, logoImgLinkList = homeImgLinkConfig_1.clientBrandsImgLinks.logoImgLinkList;
var jpmedImgLink = logoImgLinkList.jpmedImgLink, snowpeakImgLink = logoImgLinkList.snowpeakImgLink, pumaImgLink = logoImgLinkList.pumaImgLink, makeupforeverImgLink = logoImgLinkList.makeupforeverImgLink, columbiaImgLink = logoImgLinkList.columbiaImgLink, timberlandImgLink = logoImgLinkList.timberlandImgLink, vansImgLink = logoImgLinkList.vansImgLink, soniceImgLink = logoImgLinkList.soniceImgLink, northfaceImgLink = logoImgLinkList.northfaceImgLink, triumphImgLink = logoImgLinkList.triumphImgLink, philipsImgLink = logoImgLinkList.philipsImgLink, dickiesImgLink = logoImgLinkList.dickiesImgLink, skechersImgLink = logoImgLinkList.skechersImgLink, cosmedImgLink = logoImgLinkList.cosmedImgLink, wacoalImgLink = logoImgLinkList.wacoalImgLink, familyImgLink = logoImgLinkList.familyImgLink, nikoImgLink = logoImgLinkList.nikoImgLink, durexImgLink = logoImgLinkList.durexImgLink, levisImgLink = logoImgLinkList.levisImgLink, kiplingImgLink = logoImgLinkList.kiplingImgLink, threemImgLink = logoImgLinkList.threemImgLink, poyabuyImgLink = logoImgLinkList.poyabuyImgLink, lanewImgLink = logoImgLinkList.lanewImgLink, stayrealImgLink = logoImgLinkList.stayrealImgLink;
exports.clientBrandSectionConfig = {
    sectionBgColor: '#f5f9fc',
    desktopTitle: {
        first: '知名零售品牌',
        second: 'D2C 經營首選',
    },
    mobileTitle: {
        first: '知名零售品牌',
        second: 'D2C 經營首選',
    },
    content: '國際品牌 PHILIPS、DIOR、法國嬌蘭、PUMA、Keds、durex、SKECHERS 等，零售通路如全聯、全家便利商店，都選擇 91APP。',
    oneThousandImg: oneThousandImgLink,
    desktoplogoList: [
        {
            imgUrl: cosmedImgLink,
            linkUrl: 'https://shop.cosmed.com.tw/',
            alt: '康是美 Logo',
        },
        {
            imgUrl: poyabuyImgLink,
            linkUrl: 'https://www.poyabuy.com.tw/',
            alt: '寶雅線上買 Logo',
        },
        {
            imgUrl: jpmedImgLink,
            linkUrl: 'https://www.jpmed.com.tw/',
            alt: '日藥本舖 Logo',
        },
        {
            imgUrl: familyImgLink,
            linkUrl: 'https://mart.family.com.tw/',
            alt: '全家行動購 Logo',
        },
        {
            imgUrl: makeupforeverImgLink,
            linkUrl: 'https://www.makeupforever.com.tw/',
            alt: 'MAKE UP FOR EVER Logo',
        },
        {
            imgUrl: pumaImgLink,
            linkUrl: 'https://tw.puma.com/',
            alt: 'PUMA Logo',
        },
        {
            imgUrl: timberlandImgLink,
            linkUrl: 'https://www.timberland.com.tw/',
            alt: 'Timberland Logo',
        },
        {
            imgUrl: northfaceImgLink,
            linkUrl: 'https://www.thenorthface.com.tw/',
            alt: 'The North Face Logo',
        },
        {
            imgUrl: vansImgLink,
            linkUrl: 'https://www.vans.com.tw/',
            alt: 'Vans Logo',
        },
        {
            imgUrl: dickiesImgLink,
            linkUrl: 'https://www.dickies.com.tw/',
            alt: 'Dickies Logo',
        },
        {
            imgUrl: levisImgLink,
            linkUrl: 'https://www.levis.com.tw/',
            alt: 'Levis Logo',
        },
        {
            imgUrl: columbiaImgLink,
            linkUrl: 'https://www.columbiasportswear.com.tw/',
            alt: 'Columbia Logo',
        },
        {
            imgUrl: skechersImgLink,
            linkUrl: 'https://shop.skechers-twn.com/',
            alt: 'SKECHERS Logo',
        },
        {
            imgUrl: kiplingImgLink,
            linkUrl: 'https://shop.kipling.com.tw/',
            alt: 'Kipling Logo',
        },
        {
            imgUrl: snowpeakImgLink,
            linkUrl: 'https://www.snowpeak.com.tw/',
            alt: 'Snow Peak Logo',
        },
        {
            imgUrl: nikoImgLink,
            linkUrl: 'https://www.dot-st.tw/',
            alt: 'Niko and Logo',
        },
        {
            imgUrl: stayrealImgLink,
            linkUrl: 'https://tw.istayreal.com/',
            alt: 'StayReal Logo',
        },
        {
            imgUrl: lanewImgLink,
            linkUrl: 'https://www.lanew.com.tw/',
            alt: 'Lanew Logo',
        },
        {
            imgUrl: soniceImgLink,
            linkUrl: 'https://www.so-nice.com.tw/',
            alt: 'SO NICE Logo',
        },
        {
            imgUrl: triumphImgLink,
            linkUrl: 'https://www.triumphshop.com.tw/',
            alt: 'Triumph Logo',
        },
        {
            imgUrl: wacoalImgLink,
            linkUrl: 'https://shop.wacoal.com.tw/',
            alt: 'Wacoal Logo',
        },
        {
            imgUrl: philipsImgLink,
            linkUrl: 'https://www.store-philips.tw/',
            alt: 'PHILIPS Logo',
        },
        {
            imgUrl: threemImgLink,
            linkUrl: 'https://shop.3m.com.tw/',
            alt: '3M Logo',
        },
        {
            imgUrl: durexImgLink,
            linkUrl: 'https://www.durex-store.com.tw/',
            alt: 'durex Logo',
        },
    ],
    mobilelogoList: [
        {
            imgUrl: cosmedImgLink,
            linkUrl: 'https://shop.cosmed.com.tw/',
            alt: '康是美 Logo',
        },
        {
            imgUrl: jpmedImgLink,
            linkUrl: 'https://www.jpmed.com.tw/',
            alt: '日藥本舖 Logo',
        },
        {
            imgUrl: snowpeakImgLink,
            linkUrl: 'https://www.snowpeak.com.tw/',
            alt: 'Snow Peak Logo',
        },
        {
            imgUrl: pumaImgLink,
            linkUrl: 'https://tw.puma.com/',
            alt: 'PUMA Logo',
        },
        {
            imgUrl: makeupforeverImgLink,
            linkUrl: 'https://www.makeupforever.com.tw/',
            alt: 'MAKE UP FOR EVER Logo',
        },
        {
            imgUrl: columbiaImgLink,
            linkUrl: 'https://www.columbiasportswear.com.tw/',
            alt: 'Columbia Logo',
        },
        {
            imgUrl: timberlandImgLink,
            linkUrl: 'https://www.timberland.com.tw/',
            alt: 'Timberland Logo',
        },
        {
            imgUrl: vansImgLink,
            linkUrl: 'https://www.vans.com.tw/',
            alt: 'Vans Logo',
        },
        {
            imgUrl: soniceImgLink,
            linkUrl: 'https://www.so-nice.com.tw/',
            alt: 'SO NICE Logo',
        },
        {
            imgUrl: northfaceImgLink,
            linkUrl: 'https://www.thenorthface.com.tw/',
            alt: 'The North Face Logo',
        },
        {
            imgUrl: triumphImgLink,
            linkUrl: 'https://www.triumphshop.com.tw/',
            alt: 'Triumph Logo',
        },
        {
            imgUrl: philipsImgLink,
            linkUrl: 'https://www.store-philips.tw/',
            alt: 'PHILIPS Logo',
        },
        {
            imgUrl: dickiesImgLink,
            linkUrl: 'https://www.dickies.com.tw/',
            alt: 'Dickies Logo',
        },
        {
            imgUrl: skechersImgLink,
            linkUrl: 'https://shop.skechers-twn.com/',
            alt: 'SKECHERS Logo',
        },
        {
            imgUrl: familyImgLink,
            linkUrl: 'https://mart.family.com.tw/',
            alt: '全家行動購 Logo',
        },
    ],
};
