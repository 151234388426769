"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var homeImgLinkConfig_1 = require("@configs/homeImgLinkConfig");
var featureImglink01 = homeImgLinkConfig_1.nineyiFeatureImgLinks.featureImglink01, featureImglink02 = homeImgLinkConfig_1.nineyiFeatureImgLinks.featureImglink02, featureImglink03 = homeImgLinkConfig_1.nineyiFeatureImgLinks.featureImglink03;
exports.nineyiFeatureConfig = {
    sectionTitle: 'Commerce X Marketing',
    sectionSecondTitle: '雙軸進化  驅動 D2C 品牌成長循環',
    featureList: [
        {
            title: '',
            subTitle: '',
            hightLightlist: [],
            imgUrl: featureImglink01,
            isImgOnRight: false,
            isImgOnCenter: true,
            isShowGoMoreBtn: false,
            goMoreInfoId: '',
            goMoreInfoText: '',
            goMoreInfoTextLink: '',
        },
        {
            title: 'Commerce Solution 商務解決方案',
            subTitle: '帶動全通路銷售成長',
            hightLightlist: [
                '品牌官網 / App',
                '會員關係經營與升級',
                'OMO 虛實通路融合',
                '全通路庫存管理',
                'AI 賦能營運效率',
            ],
            imgUrl: featureImglink02,
            isImgOnRight: false,
            isImgOnCenter: false,
            isShowGoMoreBtn: false,
            goMoreInfoId: 'GA-feature01-goMoreBtn',
            goMoreInfoText: '瞭解更多',
            goMoreInfoTextLink: '/product-overview/#commerce-cloud',
        },
        {
            title: 'Marketing Solution 行銷解決方案',
            subTitle: '培養高價值品牌流量池',
            hightLightlist: [
                '全通路品牌數據整合',
                '用戶分群和個人化行銷',
                '第一方數據：活化會員',
                '第三方數據：拓展新客',
                'AI 賦能行銷效益',
            ],
            imgUrl: featureImglink03,
            isImgOnRight: true,
            isImgOnCenter: false,
            isShowGoMoreBtn: false,
            goMoreInfoId: 'GA-feature04-goMoreBtn',
            goMoreInfoText: '瞭解更多',
            goMoreInfoTextLink: '/product-overview/#omni',
        },
    ],
};
