"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var homeImgLinkConfig_1 = require("@configs/homeImgLinkConfig");
var bottomOrangebgImgLink = homeImgLinkConfig_1.bottomImgLinks.bottomOrangebgImgLink, bottomPhoneImgLink = homeImgLinkConfig_1.bottomImgLinks.bottomPhoneImgLink;
exports.bottomConfig = {
    sectionBgImg: bottomOrangebgImgLink,
    bottomPhoneImg: bottomPhoneImgLink,
    firstTitle: '實現品牌虛實融合 OMO，',
    sedTitle: '帶動營收成長就是現在！',
    contactBtnId: 'GA-bottom-contactBtn',
    contactBtnText: '請專人與我聯繫',
    contactBtnLink: '/consultation/',
};
