"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = require("styled-components");
var GlobalStyle = styled_components_1.createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    *{\n        box-sizing: border-box; \n        color: #222222;\n        text-decoration: none;\n    }\n\n    html {\n        width: 100vw;\n        font-family: 'Noto Sans TC', 'Microsoft JhengHei', sans-serif;\n    }\n\n    body {\n        position: relative;\n        width: 100vw;\n        -webkit-overflow-scrolling: touch;\n        ", "\n        // \u5728 safari \u4E2D\uFF0C\u8981\u5BE6\u73FE <body> \u7684 overflow-y: hidden\uFF0C\u5C31\u8981\u5728 <body> \u52A0\u4E0A height: 100vh (100% \u4E5F\u4E0D\u884C)\u3002\n    }\n\n    h2 {\n        font-weight: 500;\n        letter-spacing: 1.2px;\n        line-height: 1.5;\n\n        @media screen and (max-width: 414px) {\n            letter-spacing: normal;\n            line-height: 1.2;\n        }\n    }\n\n    h3 {\n        font-weight: 400;\n    }\n\n    p {\n        font-weight: 400;\n    }\n\n    a {\n        display: inline-block;\n    }\n\n    img {\n        width: 100%;\n        height: 100%;\n    }\n\n    input {\n        outline: none;\n    }\n\b"], ["\n    *{\n        box-sizing: border-box; \n        color: #222222;\n        text-decoration: none;\n    }\n\n    html {\n        width: 100vw;\n        font-family: 'Noto Sans TC', 'Microsoft JhengHei', sans-serif;\n    }\n\n    body {\n        position: relative;\n        width: 100vw;\n        -webkit-overflow-scrolling: touch;\n        ",
    "\n        // \u5728 safari \u4E2D\uFF0C\u8981\u5BE6\u73FE <body> \u7684 overflow-y: hidden\uFF0C\u5C31\u8981\u5728 <body> \u52A0\u4E0A height: 100vh (100% \u4E5F\u4E0D\u884C)\u3002\n    }\n\n    h2 {\n        font-weight: 500;\n        letter-spacing: 1.2px;\n        line-height: 1.5;\n\n        @media screen and (max-width: 414px) {\n            letter-spacing: normal;\n            line-height: 1.2;\n        }\n    }\n\n    h3 {\n        font-weight: 400;\n    }\n\n    p {\n        font-weight: 400;\n    }\n\n    a {\n        display: inline-block;\n    }\n\n    img {\n        width: 100%;\n        height: 100%;\n    }\n\n    input {\n        outline: none;\n    }\n\b"])), function (_a) {
    var isStopBodyScroll = _a.isStopBodyScroll;
    return isStopBodyScroll ? 'height: 100vh; overflow-y:hidden;' : 'height: 100%; overflow-y:scroll;';
});
exports.default = GlobalStyle;
var templateObject_1;
