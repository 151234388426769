"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_spring_1 = require("react-spring");
var useAnimationShow = function (_a) {
    var _b = _a.startPositionX, startPositionX = _b === void 0 ? '0px' : _b, _c = _a.startPositionY, startPositionY = _c === void 0 ? '0px' : _c, _d = _a.startPositionZ, startPositionZ = _d === void 0 ? '0px' : _d, springEffect = _a.springEffect, duration = _a.duration;
    var _e = react_1.useState(false), isShow = _e[0], setIsShow = _e[1];
    var animation = react_spring_1.useSpring({
        transform: isShow
            ? 'translate3d(0px, 0px, 0px)'
            : "translate3d(" + startPositionX + ", " + startPositionY + ", " + startPositionZ + ")",
        opacity: isShow ? '1' : '0',
        config: springEffect ? __assign({}, react_spring_1.config[springEffect]) : { duration: duration },
    });
    return { animation: animation, setIsShow: setIsShow };
};
exports.default = useAnimationShow;
