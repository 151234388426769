"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var usePopUp = function () {
    var _a = react_1.useState(false), isShowPopUp = _a[0], setIsShowPopUp = _a[1];
    var togglePopUp = function () {
        setIsShowPopUp(!isShowPopUp);
    };
    return { isShowPopUp: isShowPopUp, togglePopUp: togglePopUp };
};
exports.default = usePopUp;
