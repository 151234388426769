"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var homeImgLinkConfig_1 = require("@configs/homeImgLinkConfig");
var fbImglink = homeImgLinkConfig_1.footerImgLinks.fbImglink, igImgLink = homeImgLinkConfig_1.footerImgLinks.igImgLink, ytImgLink = homeImgLinkConfig_1.footerImgLinks.ytImgLink, linkedinImgLink = homeImgLinkConfig_1.footerImgLinks.linkedinImgLink, lineImgLink = homeImgLinkConfig_1.footerImgLinks.lineImgLink;
var basicURL = '';
exports.footerConfig = {
    footerList: [
        {
            title: '產品功能',
            isHaveSocailList: false,
            list: [
                {
                    text: '功能列表',
                    link: basicURL + "/features-app/",
                    isTargetBlank: false,
                },
                {
                    text: '產品更新紀錄',
                    link: basicURL + "/product-release/",
                    isTargetBlank: false,
                },
            ],
        },
        {
            title: '方案介紹',
            isHaveSocailList: false,
            list: [
                {
                    text: 'APP 旗艦方案',
                    link: basicURL + "/appecommerce/",
                    isTargetBlank: false,
                },
                {
                    text: '門市企業方案',
                    link: basicURL + "/omnichannel/",
                    isTargetBlank: false,
                },
                {
                    text: 'OMO 旗艦方案',
                    link: basicURL + "/omocrm/",
                    isTargetBlank: false,
                },
                {
                    text: '360品牌數位代營運',
                    link: basicURL + "/360-tp-operation/",
                    isTargetBlank: false,
                },
            ],
        },
        {
            title: '服務與支援',
            isHaveSocailList: false,
            list: [
                {
                    text: '91APP 品牌新零售學院',
                    link: basicURL + "/blog/",
                    isTargetBlank: false,
                },
                {
                    text: '商店經營 Q&A',
                    link: "https://support.91app.com/hc",
                    isTargetBlank: false,
                },
                {
                    text: '91APP API 開發者專區',
                    link: basicURL + "/developers/",
                    isTargetBlank: false,
                },
                {
                    text: '91APP 策略合作夥伴計畫',
                    link: basicURL + "/partner-program/",
                    isTargetBlank: false,
                },
            ],
        },
        {
            title: null,
            isHaveSocailList: false,
            list: [
                {
                    text: '關於我們',
                    link: basicURL + "/about/",
                    isTargetBlank: false,
                },
                {
                    text: '人才招募',
                    link: basicURL + "/careers/",
                    isTargetBlank: false,
                },
                {
                    text: '合作品牌',
                    link: basicURL + "/showcases/",
                    isTargetBlank: false,
                },
                {
                    text: '新聞中心',
                    link: basicURL + "/news/",
                    isTargetBlank: false,
                },
                {
                    text: '信託銀行揭露事項',
                    link: basicURL + "/bank-disclosures/",
                    isTargetBlank: false,
                },
                {
                    text: '91APP Payments 服務條款',
                    link: basicURL + "/provision-on-member/",
                    isTargetBlank: false,
                },
            ],
        },
        {
            title: null,
            isHaveSocailList: true,
            list: [
                {
                    text: '投資人關係',
                    link: "https://ir.91app.com/tw/index.php",
                    isTargetBlank: false,
                },
                {
                    text: '利害關係人',
                    link: "https://ir.91app.com/tw/stakeholder.php",
                    isTargetBlank: false,
                },
                {
                    text: '聯絡我們',
                    link: basicURL + "/contactus/",
                    isTargetBlank: false,
                },
            ],
        },
    ],
    socailList: [
        {
            imgUrl: fbImglink,
            imgAlt: 'FB link',
            link: 'https://www.facebook.com/91APP/',
        },
        {
            imgUrl: igImgLink,
            imgAlt: 'IG link',
            link: 'https://www.instagram.com/91app_taiwan/',
        },
        {
            imgUrl: ytImgLink,
            imgAlt: 'YouTube link',
            link: 'https://www.youtube.com/channel/UCOl1kLoXKctu8q5vz2jHcMQ',
        },
        {
            imgUrl: linkedinImgLink,
            imgAlt: 'linkedin link',
            link: 'https://www.linkedin.com/company/91app-inc-/',
        },
        {
            imgUrl: lineImgLink,
            imgAlt: 'line link',
            link: 'https://page.line.me/91app',
        },
    ],
};
exports.footerCountryConfig = [
    {
        id: 'tw',
        link: '/',
        text: '台灣',
    },
    {
        id: 'hk',
        link: 'https://www.91app.com.hk/',
        text: 'Hong Kong',
    },
    {
        id: 'my',
        link: '/?lang=MY',
        text: 'Malaysia',
    },
];
